import styled from "@emotion/styled"
import React from "react"
import { device } from "../layout/GlobalStyles"
import ecclesiaHero from "../../images/programs/ecclesia/hero-ecclesia.svg"
import ecclesiaLogo from "../../images/programs/logo-ecclesia-no-title.svg"
import { HeroContainer } from "./Hero-Container"

const Container = styled.div`
  .ecclesia-logo {
    width: 60px;
    position: relative;
    margin-bottom: 10px;
  }
  @media ${device.laptop} {
    margin-bottom: -30px;
    margin-top: 20px;
    .hero-image {
      min-width: 700px;
    }
  }
`

export const EcclesiaHero = () => {
  return (
    <Container>
      <HeroContainer className="container">
        <div>
          <img
            className="ecclesia-logo"
            src={ecclesiaLogo}
            alt="animated students in classroom"
          />
          <h1>Ecclesia</h1>
          <p>
            A program of
            <br /> MLIFE Church
          </p>
        </div>
        <img
          className="hero-image"
          src={`${ecclesiaHero}`}
          alt="animated students in classroom"
        />
      </HeroContainer>
    </Container>
  )
}
