import styled from "@emotion/styled"
import React from "react"
import { fonts, device, colors } from "../layout/GlobalStyles"
import peaceJustice from "../../images/programs/peace-justice.svg"
import ecclesiaLogo from "../../images/programs/logo-ecclesia.svg"
import mlifeLogo from "../../images/logos/logo-regular.svg"
import sdgLogo from "../../images/programs/logo-un.svg"

const BioContainer = styled.section`
  padding-bottom: 72px;
  .line {
    width: 1px;
    height: 90px;
    background: black;
  }
  @media ${device.laptop} {
    padding-bottom: 166px;
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LeftPanel = styled.div`
  padding: 72px 0px;

  h2 {
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    margin: 0em 0px 10px 00px;
    line-height: 65px;
  }
  p {
    margin: 0 0px 30px 0px;
    color: ${colors.primary2};
    font-size: 40px;
    letter-spacing: -1.25px;
    line-height: 48px;
    font-family: ${fonts.secondary};
  }
  span {
    font-weight: 500;
    position: relative;
    top: 10px;
    font-family: Montserrat;
    font-size: 18px;
    letter-spacing: -0.69px;
    line-height: 36px;
  }

  div {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      width: 1px;
      height: 80px;
      position: relative;
      top: 10px;

      background: black;
    }
    .logo {
      width: 140.3px;
    }
  }
  @media ${device.laptop} {
    h2 {
      margin-bottom: 15px;
    }
    div {
      margin: -5px 0px 15px;
    }
    p + div {
      width: 363px;
    }
    min-width: 463px;
  }
  @media ${device.laptopL} {
    p {
      max-width: 418px;
    }
  }
`

const RightPanel = styled.div`
  div {
    display: flex;
  }
  p {
    font-size: 22px;
  }
  .line {
    width: 1px;
    background: ${colors.black};
  }
  .logo-container {
    justify-content: space-between;
  }
  @media ${device.laptop} {
    margin-top: 0px;
    max-width: 553px;
    padding: 0px 0px 0px;
    position: relative;
    top: 50px;
    div {
      width: 326px;
      margin-top: 0px;
    }
  }
  p:first-of-type {
    margin-top: 50px;
  }
  p + p {
    margin-top: 30px;
    margin-bottom: 0;
  }
`

export const EcclesiaBio = () => {
  return (
    <BioContainer className="container">
      <LeftPanel>
        <h2>Our Goal</h2>
        <p>Build intentional communities.</p>
        <div>
          <img className="logo" src={mlifeLogo} alt="mlife logo" />
          <div></div>
          <img src={sdgLogo} alt="SDG logo" />
        </div>
        <span>MLIFE support(s) the Sustainable Development Goals. </span>
      </LeftPanel>
      <RightPanel>
        <div className="logo-container">
          <img
            className="program-logo"
            src={ecclesiaLogo}
            alt="ecclesia logo"
          />
          <div className="line"></div>
          <img
            src={peaceJustice}
            className="box-image"
            alt="peace, justice and strong institutions"
          />
        </div>
        <section>
          <p>
            Many people are leaving religion as we understand it because they're
            afraid of being shunned, criticized, and rejected for who they are
            and what makes them human. They are disavowing faith tenets that
            openly support oppression, hate, and nationalism over liberty, love,
            and community.
          </p>
          <p>
            MLIFE's Ecclesia program harnesses the spiritual and social capital
            of those at the margins, locally and globally, to be heard and
            answered.
          </p>
        </section>
      </RightPanel>
    </BioContainer>
  )
}
