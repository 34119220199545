import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"
import { StaticImage } from "gatsby-plugin-image"
import leapLogoWhite from "../images/programs/logo-leap-white.svg"
import mloveLogoWhite from "../images/programs/logo-mlove-white.svg"
import rapidLogoWhite from "../images/programs/logo-rapid-white.svg"
import graceLogoWhite from "../images/programs/logo-grace-white.svg"
import ecclesiaLogoWhite from "../images/programs/logo-ecclesia-white.svg"
import { Link } from "gatsby"

const StyledEcclesiaTea = styled.div`
  background: ${colors.primary2};
  padding: 72px 0px;
  h3,
  p {
    color: ${colors.white};
  }
  p {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.laptop} {
    padding: 128px 0px 76px;
  }
`

const TopSection = styled.div`
  h3 {
    font-size: 36px;
    font-weight: 800;
    letter-spacing: -1.25px;
    line-height: 42px;
  }
  @media ${device.tablet} {
    h3 {
      font-size: 40px;
      font-weight: 800;
      letter-spacing: -1.25px;
      line-height: 48px;
    }
  }
  @media ${device.laptop} {
    display: flex;
    margin-bottom: -20px;
    justify-content: space-between;
    div:first-of-type {
      position: relative;
      right: 80px;
      img {
        height: 370px;
        width: 536.93px;
      }
    }

    .text-container {
      width: 503px;

      h3 {
        margin: 10px 0px 25px 0px;
      }
      p {
        padding: 0;
      }
      p:last-of-type {
        margin-top: 32px;
      }
    }
  }
  @media ${device.laptopL} {
    .text-container {
      width: 553px;
    }
  }
`

const Panels = styled.div`
  @media ${device.tablet} {
    margin-top: 72px;
  }
  @media ${device.laptop} {
    margin-top: 90px;
  }
`

const ProgramPanel = styled.div`
  padding: 36px 0px;
  border-top: 1px solid ${colors.secondary202};
  h3 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -1.25px;
    line-height: 48px;
    margin: 0 0px 15px 0px;
  }
  p {
    padding-right: 30px;
  }
  p + span {
    color: ${colors.white};
    font-family: ${fonts.primary};
    font-size: 22px;
    font-weight: 800;
  }

  span {
    font-weight: 800;
  }
  @media ${device.tablet} {
    p {
      padding-right: 50px;
    }
  }
  @media ${device.laptop} {
    padding: 64px 0px;
    display: flex;
    justify-content: space-between;
    .panel-text-container {
      width: 743px;
      p {
        margin-bottom: 25px;
      }
    }
    h3 {
    }
    p {
      span {
        margin: 0;
        padding: 00px;
      }
      margin: 0;
      padding: 00px;
    }
  }
`

export const EcclesiaTea = () => {
  return (
    <StyledEcclesiaTea>
      <TopSection className="container">
        <StaticImage
          src="../images/programs/ecclesia/teacup.png"
          placeholder="none"
          quality={100}
          alt="large teacup"
        />
        <div className="text-container">
          <h3>Our spirituali-TEA communities</h3>
          <p>
            Our Spirituali-TEA communities are bonded together by love and
            acceptance. We are multifaith, multicultural, and multiethnic,
            centering on the spirituality of Black, Indigenous, and People of
            Color while welcoming all faiths, colors, nationalities, sexual
            orientations, and identities to wholeness and justice.
          </p>
          <p>
            No matter your age, there’s always a way to be plugged in Ecclesia!
          </p>
        </div>
      </TopSection>
      <Panels className="container">
        <ProgramPanel>
          <div>
            <h3>Scholars</h3>
            <img src={leapLogoWhite} alt="leap logo" />
          </div>
          <div className="panel-text-container">
            <p>
              <span>Our LEAP program</span> supports children and transitional
              youths ages 5-20 years old. Our programming emphasizes reading
              critically, thinking creatively, writing clearly, and speaking
              confidently.{" "}
            </p>
            <Link to="/programs/leap/">
              <Button color="yellow">Get details</Button>
            </Link>
          </div>
        </ProgramPanel>

        <ProgramPanel>
          <div>
            <h3>Fellows</h3>
            <img src={mloveLogoWhite} alt="mlove logo" />
          </div>
          <div className="panel-text-container">
            <p>
              <span>Our MLOVE program</span> is our young adult program for ages
              21-34 years old. We model authentic emotional intimacy and
              self-work for our fellows to love, learn, and lead. We believe we
              can change the world when we Lead with Love.
            </p>
            <Link to="/programs/mlove/">
              <Button color="yellow">Get details</Button>
            </Link>
          </div>
        </ProgramPanel>

        <ProgramPanel>
          <div>
            <h3>Seekers</h3>
            <img src={rapidLogoWhite} alt="rapid logo" />
          </div>
          <div className="panel-text-container">
            <p>
              <span>Our RAPID program</span> supports families in need of small
              financial assistance to meet desperate needs and provides small
              capital grants for solutionists.
            </p>
            <Link to="/programs/rapid/">
              <Button color="yellow">Get details</Button>
            </Link>
          </div>
        </ProgramPanel>
        <ProgramPanel>
          <div>
            <h3>Helpers</h3>
            <img src={graceLogoWhite} alt="grace logo" />
          </div>
          <div className="panel-text-container">
            <p>
              <span>Our Spaces of Grace program</span> is a welcoming, healing,
              and affirming space for everyone.
            </p>
            <Link to="/programs/spaces-of-grace/">
              <Button color="yellow">Get details</Button>
            </Link>
          </div>
        </ProgramPanel>
        <ProgramPanel>
          <div>
            <h3>Guardians</h3>
            <img src={ecclesiaLogoWhite} alt="ecclesia logo" />
          </div>
          <div className="panel-text-container">
            <p>
              <span>Our Ecclesia program</span> everyone to donate and engage
              our communities through mentorship and coaching.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://forms.gle/57iAYyquQUuuQNPG6"
            >
              <Button color="yellow">Get details</Button>
            </a>
          </div>
        </ProgramPanel>
      </Panels>
    </StyledEcclesiaTea>
  )
}
