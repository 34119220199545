import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"

const MainContainer = styled.div`
  padding: 72px 0px 42px;
  background: ${colors.primary1};
  h4 {
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 54px;
    margin: 0;
  }
  p {
    color: ${colors.dark3};
  }

  @media ${device.tablet} {
    h4 {
      line-height: 55px;
      margin-bottom: 30px;
    }
  }
  @media ${device.laptop} {
    padding: 120px 0px 86px;
    .eclessia-paragraph {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.69px;
      line-height: 36px;
      width: 850px;
      margin-top: 30px;
    }
    .sub-header {
      margin: 0px 0px -10px;
    }
    h4 {
      margin-bottom: 30px;
    }
  }
`

const Events = styled.div`
  @media ${device.tablet} {
    display: flex;
  }
`

const Event = styled.div`
  margin-top: 25px;
  p:first-of-type {
    font-size: 30px;
    font-weight: 900;
    margin: 0px 25px 0px;
    padding: 0;
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  p:last-of-type {
    margin: 0;
    padding: 0;
  }
  ul {
    li {
      color: ${colors.dark3};
      width: 500px;
    }
    li {
      font-size: 22px;
      font-family: ${fonts.primary};
      margin: 12px 0px;
    }
    margin: 0px 0px 0px 0px;
    padding: 0;
    list-style: none;
  }
  @media ${device.tablet} {
    margin: 0px 0px 0px 0px;
  }
  @media ${device.laptop} {
    margin: 0px 0px 0px 0px;
    ul {
      margin-right: 90px;
      width: 195px;
    }
  }
`

const MiddleSection = styled.div`
  margin: 54px 0px;
  h5 {
    font-size: 22px;
    font-weight: 900;
    color: ${colors.dark3};
    margin: 0;
    font-family: ${fonts.primary};
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  p {
    letter-spacing: -0.69px;
    line-height: 36px;
    margin: 0;
    font-size: 22px;
  }
  @media ${device.tablet} {
    h5 {
      font-size: 30px;
    }
  }
  @media ${device.laptop} {
    margin: 72px 0px 30px;
    .sub-header {
      margin: 0;
    }
    h5 {
      margin-bottom: 20px;
    }
    p {
      width: 762px;
    }
  }
`

const BottomSection = styled.div`
  p {
    margin: 30px 0px 30px 0px;
    color: ${colors.dark3};
  }
  span {
    font-weight: 900;
  }
  button {
    margin: 15px 0px;
  }
  h5 {
    font-size: 22px;
    font-weight: 900;
    color: ${colors.dark3};
    margin: 0;
    font-family: ${fonts.primary};
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    p {
      max-width: 760px;
      font-size: 22px;
    }
    h5 {
      font-size: 30px;
    }
  }
  @media ${device.laptop} {
    h5 {
      margin-bottom: 15px;
      font-size: 30px;
    }
    .button-container {
      display: flex;
      margin-top: 10px;
      margin-bottom: 113px;
      align-items: center;
      button {
        margin-right: 50px;
      }
    }
  }
`

export const EcclesiaEvents = () => {
  return (
    <MainContainer>
      <div className="container">
        <h4>Events</h4>
        <p
          className="sub-header"
          style={{
            fontWeight: "900",
            color: `${colors.dark3}`,
            fontSize: "30px",
          }}
        >
          Join our Sacred Circle.
        </p>
        <p className="eclessia-paragraph">
          Ecclesia holds the Sacred Circle every last Tuesday of the month.
          Times are both in Pacifict Time PT) and East Africa Time (EAT){" "}
        </p>
        <Events>
          <Event>
            <ul>
              <li>Mondays - 8 - 9 pm (PT) | 7 - 8am (EAT) </li>
            </ul>
          </Event>
        </Events>

        <BottomSection>
          <div>
            <p>
              <span>Interested in joining us? </span>
              All Sacred Gatherings are held via a Zoom call until further
              notice
            </p>
          </div>
          <div className="button-container">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/QvkswEQ4XJE9ZaMP9"
            >
              <Button color="yellow">RSVP</Button>
            </a>
            <p>
              or email us{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:ecclesia@mlifechurch.org"
              >
                <span style={{ color: `${colors.primary2}` }}>
                  ecclesia@mlifechurch.org
                </span>
              </a>
            </p>
          </div>
        </BottomSection>
        <MiddleSection>
          <h5>The annual fundraising gala. </h5>
          <p>
            On the first Saturday in November of each year, we host fifty
            influencers, movers, and activists from all over the world in the
            Bay Area, California, for an evening filled with fun and food.
            Ecclesia is a premier dinner intended to create an intimate and
            familial space for everyone to relax, socialize, and network.{" "}
          </p>
        </MiddleSection>
      </div>
    </MainContainer>
  )
}
