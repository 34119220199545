import React from "react"
import { EcclesiaHero } from "../../components/heros/Ecclesia-Hero"
import { BecomeOneOfUs } from "../../components/Become-One-Of-Us"
import { EcclesiaPanels } from "../../components/panels/Ecclesia-Panels"
import { EcclesiaBio } from "../../components/programs-bio/Ecclesia-Bio"
import { ImageContainer } from "../../components/Image-Container"
import { StaticImage } from "gatsby-plugin-image"
import { EcclesiaTea } from "../../components/Ecclesia-Tea"
import { EcclesiaEvents } from "../../components/Ecclesia-Events"
import { Seo } from "../../components/Seo"

const Ecclesia = () => {
  return (
    <>
      <Seo
        title="Ecclesia - A program of MLIFE Church"
        description="We create a spiritual space that emphasizes authenticity and the radical welcoming of those who scarcely believe they belong."
      />{" "}
      {/* <Nav /> */}
      <EcclesiaHero />
      <EcclesiaPanels />
      <EcclesiaBio />
      <ImageContainer>
        <StaticImage
          src="../../images/programs/ecclesia/speaking-to-crowd.jpeg"
          placeholder="none"
          quality={100}
          alt="mwangi speaking to a crowd"
        />
      </ImageContainer>
      <EcclesiaTea />
      <ImageContainer>
        <StaticImage
          src="../../images/programs/ecclesia/presenting-award.jpeg"
          placeholder="none"
          quality={100}
          alt="presenting an award onstage"
        />
      </ImageContainer>
      <EcclesiaEvents />
      <BecomeOneOfUs />
      {/* <Footer /> */}
    </>
  )
}

export default Ecclesia
